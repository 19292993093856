<template>
    <div>
        <Header></Header>
        <div class="container">
            <div class="row">
                <div class="col-8 offset-2 text-center">

                    <h1>Impressum</h1>
                </div>
            </div>
            <div class="row">
                                <div class="col-lg-10 offset-lg-1">
                                    <ul class="list-unstyled">
                                        <li><strong>Angaben gemäß §5 TMG</strong></li>
                                        <li>Autohaus Israel GmbH &amp; Co. KG</li>
                                        <li>Dohnaer Str. 310</li>
                                        <li>01257 Dresden</li>
                                        <li>Registergericht: Amtsgericht Dresden</li>
                                        <li>Registernummer: HRA 11064</li>
                                    </ul>
                                    <ul class="list-unstyled">
                                        <li>Persönlich haftende Gesellschafter:</li>
                                        <li>Autohaus Rädlein GmbH</li>
                                        <li>Registergericht: Amtsgericht Dresden</li>
                                        <li>Registernummer: HRB 38604</li>
                                    </ul>
                                    <ul class="list-unstyled">
                                        <li>&nbsp;</li>
                                        <li><strong>Kontakt</strong></li>
                                        <li>tel&nbsp;&nbsp;0351/28579-0</li>
                                        <li>fax&nbsp;&nbsp;0351/2857999</li>
                                        <li><a href="mailto:info@waschprofis.de">info@waschprofis.de</a></li>
                                    </ul>
                                    <ul class="list-unstyled">
                                        <li>&nbsp;</li>
                                        <li><strong>Steuernummer</strong></li>
                                        <li>203/150/39607</li>
                                        <li>&nbsp;</li>
                                        <li><strong>Umsatzsteuer-ID</strong></li>
                                        <li>USt.-Nr.: nach USTG §27</li>
                                        <li>DE 159 699 190</li>
                                        <li>&nbsp;</li>
                                        <li><strong>Geschäftsführung</strong></li>
                                        <li>Kay Pelzer, Tim Rädlein</li>
                                    </ul>
                                    <ul class="list-unstyled">
                                        <li>&nbsp;</li>
                                        <li><strong>Name der Schiedsstelle</strong></li>
                                        <li>Innung des Kfz-Handwerks Region Dresden</li>
                                        <li>Tiergartenstraße 94</li>
                                        <li>01219 Dresden</li>
                                    </ul>
                                    <ul class="list-unstyled">
                                        <li>&nbsp;</li>
                                        <li><strong>Verbraucherinformation gemäß Verordnung (EU) Nr. 524/2013</strong></li>
                                        <li class="list-style">Im Rahmen der Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten steht Ihnen unter <a href="http://ec.europa.eu/consumers/odr/" target="_blank">https://ec.europa.eu/consumers/odr/</a> eine Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung.<br> Unsere E-Mail-Adresse lautet: <a href="mailto:autohaus.israel@t-online.de">autohaus.israel@t-online.de</a></li>
                                        <li class="list-style">Unser Autohaus ist zur Teilnahme an einem Streitbeilegungsverfahren einer Verbraucherschlichtungstelle nicht bereit und nicht verpflichtet.</li>
                                    </ul>
                                </div>
                            </div>
        </div>
        <div class="red-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7 offset-lg-1 col-xl-4 offset-xl-2">
                        <h3>Werde Waschprofi!</h3>
                    </div>
                    <div class="col-md-4 col-xl-3">
                        <router-link to="/offer" class="button white">
                            Stellenangebote
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";

    export default {
        name: 'About',
        components: {
            Header,
            Footer,
        },
    }
</script>
<style lang="scss" scoped>
    h1{
        font-weight:400;
        font-size: 21px;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    h2{
        font-family: 'futura-pt-bold';
        font-size: 60px;
        letter-spacing: 1.5px;
        margin-bottom: 50px;
    }

    p,
    li{
        font-size: 22px;
        letter-spacing: 0.55px;
    }

    .red-bg{
        margin-top:110px;
        padding-top:90px;
        padding-bottom:90px;

        h3{
            font-family: 'futura-pt-bold';
            font-size:40px;
            letter-spacing: 1px;
        }
    }


</styles>